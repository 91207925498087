import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import PageContainer from "../components/Layout/PageContainer/PageContainer";

const thankYou = () => (
  <Layout>
    <SEO title="Thank you for contacting us" />
    <PageContainer
      title="Thank you"
      subtitle="Your Message Was Sent. We Will Get In Touch As Soon As We Can."
      textContainer
    ></PageContainer>
  </Layout>
);

export default thankYou;
